import React, { useLayoutEffect, useState } from "react";
import AuthContainer from "../components/AuthContainer/AuthContainer";
import GridLGHidden from "../components/GridComponent/components/GridLGHidden";
import Header from "../components/Header/Header";
import TextWithBackButton from "../components/TextWithBackButton";
import { Form, Formik } from "formik";
import InputField from "../components/InputField/InputField";
import Button from "../components/Button/Button";
import Typography from "../components/Typography/Typography";
import { Link, useNavigate } from "react-router-dom";
import Divider from "../components/Divider/Divider";
import useLogin from "../store/Mutations/useLogin";
import { useToast } from "../context/ToastProvider";
import { LoginSchema } from "../scemas/validations";
import emailCorrections from "../utils/emailCorrections.json";
import ModalComponent from "../components/modals/ModalComponent";

const Login = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [suggestedEmail, setSuggestedEmail] = useState<string | null>(null);
  const [openSuggestionModal, setOpenSuggestionModal] = useState(false);
  const correctedEmailCorrections = emailCorrections as Record<string, string>;

  const { mutate: login, isPending } = useLogin({
    onSuccess: () => {
      navigate("/students");
    },
    onError: (error: any) => {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      addToast({
        headerText: errorMessage,
        message: "",
        type: "error",
      });
    },
  });

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#fbfbfe');
  }, []);

  const handleFormSubmit = async (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    if (values.email === '' || values.password === '') return;

    const domain = values.email.substring(values.email.indexOf('@'));
    if (correctedEmailCorrections[domain]) {
      const correctedEmail = values.email.replace(
        domain,
        correctedEmailCorrections[domain],
      );
      setSuggestedEmail(correctedEmail);
      setOpenSuggestionModal(true);
    } else {
      login({
        email: values.email.toLowerCase(),
        password: values.password,
        remember: values.remember,
      });
    }
  };

  return (
    <AuthContainer>
      <div className="lg:rounded-20 w-full bg-light-grey-5 lg:bg-white lg:h-auto lg:max-h-[90%] h-svh overflow-hidden lg:min-h-fit lg:overflow-auto px-12 pb-32 lg:p-24">
        <GridLGHidden>
          <Header />
        </GridLGHidden>
        <TextWithBackButton text="Login" backBtnClick={() => navigate("/")} />
        <Formik
          initialValues={{
            email: "",
            password: "",
            remember: false,
          }}
          validationSchema={LoginSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          {({ values, setFieldValue, handleBlur, errors, touched }) => (
            <Form className="mt-24 lg:mt-72  w-full flex flex-col gap-20 lg:gap-24 h-full lg:h-auto">
              <ModalComponent
              type="warning"
              open={openSuggestionModal}
              title="Email Correction Suggestion"
              description={`You have indicated ${values.email} as your email address. Did you mean ${suggestedEmail} instead?`}
              primaryButtonText="Yes, update"
              cancelButtonText="No, keep"
              onSubmit={() => {
                if (suggestedEmail) {
                  setFieldValue('email',suggestedEmail);
                }
                setOpenSuggestionModal(false);
                login({
                  email: (suggestedEmail || values.email).toLowerCase(),
                  password: values.password,
                  remember: values.remember,
                });
              }}
              onCancel={() => {
                setOpenSuggestionModal(false);
                setSuggestedEmail(null);
                login({
                  email: values.email.toLowerCase(),
                  password: values.password,
                  remember: values.remember,
                });
              }}
              withCancel
            />
              <InputField
                type="email"
                label="Your email address"
                required
                value={values.email}
                placeholder="Your email address"
                id="email"
                onBlur={handleBlur("email")}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
                error={touched.email && errors.email ? errors.email : ""}
              />
              <InputField
                type="password"
                label="Password"
                required
                placeholder="Password"
                id="password"
                value={values.password}
                onBlur={handleBlur("password")}
                onChange={(e) => {
                  setFieldValue("password", e.target.value);
                }}
                passwordWithIcon={true}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
              />
              <div className=" lg:my-24 flex w-full justify-between">
                <label
                  className="checkbox-container"
                  htmlFor="remember-me"
                  onChange={() => {
                    setFieldValue("remember", !values.remember);
                  }}
                >
                  <input type="checkbox" id="remember-me" />
                  <span className="checkmark"></span>
                  <Typography type="Body2" className=" ">
                    {"Remember me"}
                  </Typography>
                </label>
                <Link
                  to={"/reset-password"}
                  className="underline decoration-[#BBBBBB] decoration-1 underline-offset-4"
                  id="forgot-password"
                >
                  <Typography type="Body2" className="!text-[#BBBBBB]">
                    {"Forgot password?"}
                  </Typography>
                </Link>
              </div>

              <div className="absolute lg:relative lg:bottom-0 bottom-20 left-0 w-full lg:px-0 px-12 ">
                <Button
                  disabled={
                    isPending ||
                    !validateEmail(values.email) || 
                    values.password.length < 8 
                  }
                  buttonType="primary"
                  type="submit"
                  title={"Log in"}
                  loading={isPending}
                />
                <GridLGHidden>
                  <Divider marginY="my-20" marginX="mx-24" />
                </GridLGHidden>
                <Typography
                  type="Body2"
                  className="text-center !text-[#BBBBBB] lg:mt-24"
                >
                  Don’t have an account?{" "}
                  <Link
                    to={"/signup"}
                    className="text-primary underline underline-offset-4 ml-[3px]"
                    id="alreadyhaveAccount"
                  >
                    Sign up
                  </Link>
                </Typography>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthContainer>
  );
};

export default Login;
