import React, { useState } from 'react'
import Typography from '../Typography/Typography'
import Divider from '../Divider/Divider'
import Button from '../Button/Button'
import UserIcon from "../../assets/icons/bx-user-check-blue.svg";
import UserPlusIcon from "../../assets/icons/bx-user-plus-green.svg";
import GridSMHidden from '../GridComponent/components/GridSMHidden';
import BottomNav from '../BottomNav/BottomNav';
import GridLGHidden from '../GridComponent/components/GridLGHidden';
import PurchaseAdditionalLicensesModals from '../modals/PurchaseAdditionalLicensesModals';
import useSingleOrganization from "../../store/Querys/useSingleOrganization";
import useOrganisation from '../../store/Querys/useOrganisation';



const LicensesBox = ({onInviteBtnClick}:{onInviteBtnClick: ()=> void;}) => {
  const [purchaseLicensesModal, setPurchaseLicensesModal] = useState(false);
  const { data: organizationsData } = useOrganisation();
  const orgId = organizationsData?.organizations?.[0]?.id || "";

  const {
    data: singleOrganizationData,
    refetch: refechSingleOrganizationData,
  } = useSingleOrganization(orgId);

  return (
    <div className='flex-1 flex lg:justify-between flex-col'>
      {purchaseLicensesModal && <PurchaseAdditionalLicensesModals handleUpdateLicenses={refechSingleOrganizationData} closeBtnClick={()=>{ setPurchaseLicensesModal(false); refechSingleOrganizationData()}}/>}
        <div className="border-light-grey-10 rounded-20 border p-16 lg:p-24 mb-24 mt-16 lg:mt-0 mx-12 lg:mx-0">
         <GridSMHidden>
         <Typography type="Header3">
          License overview
        </Typography>
        <Typography type="Body2" className="text-main-gray mt-12">Current amount of licenses.</Typography>
        <Divider marginY="my-12" className='!bg-light-grey-10'/>
        </GridSMHidden>   
        <div className="flex gap-12 lg:mt-12">
          <div className="flex justify-center items-center w-60 h-60 bg-secondary rounded-20">
            <img src={UserIcon} alt="" width={36} height={36}/>
          </div>
          <div>
            <Typography type="Body">{singleOrganizationData?.totalLicenses}</Typography>
            <Typography type="Body2" className="text-main-gray">Purchased licenses.</Typography>
          </div>
        </div>
        <div className="flex gap-12 mt-12">
          <div className="flex justify-center items-center w-60 h-60 bg-[#D8F7B2] rounded-20">
            <img src={UserPlusIcon} alt="" width={36} height={36}/>
          </div>
          <div>
            <Typography type="Body">{singleOrganizationData?.usedLicenses}</Typography>
            <Typography type="Body2" className="text-main-gray">Used licenses.</Typography>
          </div>
        </div>
      </div>
      <div className='flex-1 flex flex-col justify-between'>
      <div className='px-12 lg:px-0 mb-20'>
        <Button title="Invite students" buttonType="secondary" onClick={onInviteBtnClick}/>
      </div>
      <GridSMHidden>
        <Divider className='!bg-light-grey-10'/>
      </GridSMHidden>
      <Typography type='Body2' className='text-primary text-center underline underline-offset-4 cursor-pointer mb-24 ' onClick={()=> {setPurchaseLicensesModal(true)}}>Purchase additional licenses</Typography>
      
      </div>
      <GridLGHidden>
        <BottomNav/>    
      </GridLGHidden>
    </div>
  )
}

export default LicensesBox
