import React, { useEffect, useState } from 'react'
import Typography from '../Typography/Typography';
import MoreIcon from "../../assets/icons/more-vertical.svg";
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import TrashIcon from "../../assets/icons/bx-trash-red.svg";
import Divider from '../Divider/Divider';
import ResendIcon from "../../assets/icons/mdi_email-resend-outline-black.svg";
import { useDimensions } from '../../lib/useDimensions';
import useOrganisation from '../../store/Querys/useOrganisation';
import useSendInvitations from '../../store/Mutations/useSendInvitation';
import { useToast } from '../../context/ToastProvider';
// import useDeleteInvitations from '../../store/Mutations/useDeleteInvitations';

interface StudentListItemProps{
  editable: boolean;
  email: string;
  name: string;
  status: string;
  selectedStudents: string[];
  handleCheckboxChange: () => void;
  setDropdownSettings: (val: string)=> void;
  dropdownSettings: string;
  setMoreSettings?: (studentId: string) => void;
  setSelectedStudents: (val: string[]) => void;
  setDeleteStudentsModal: (val: boolean) => void;
}

const StudentListItem = ({editable, email, name, status, setDeleteStudentsModal, setSelectedStudents,  selectedStudents, dropdownSettings, handleCheckboxChange, setDropdownSettings, setMoreSettings,}:StudentListItemProps) => {
  const isMobile = useDimensions();
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const { data: adminData } = useOrganisation();

  const sendInvitationsMutation = useSendInvitations();

  const { addToast } = useToast(); 

  useEffect(() => {
    if (adminData?.organizations?.[0]?.id) {
      setOrgId(adminData.organizations[0].id);
    }
  }, [adminData]);

  // const { mutate: deleteInvitations } = useDeleteInvitations({
  //   onSuccess: () => {
  //     console.log('Invitations deleted successfully!');
  //   },
  //   onError: (error) => {
  //     console.error('Error deleting invitations:', error);
  //   },
  // });


  // const handleDeleteInvitation = () => {
  //   deleteInvitations({
  //     orgId: orgId || '',
  //     emails: [email],
  //   });
  //   setDropdownSettings('');
  // };

  const handleInviteClick = () => {
    if (orgId) {
      sendInvitationsMutation.mutate({
        emails: [email],
        orgId,
      });
      addToast({
        headerText: 'Invitation sent successfully!',
        type: 'success',
        message: ""
      });
      setDropdownSettings('');
    }
  };

  return (
    <div className='lg:px-10 lg:py-18 flex justify-between items-center'>
         <label className={`circle-checkbox-container flex items-center ${editable ? "pl-48" : ""}`} htmlFor={email}>
            {
              editable && <>
              <input
              type="checkbox"
              id={email}
              checked={selectedStudents.includes(email)}
              onChange={handleCheckboxChange}
              />
              <span className="circle-checkmark"></span></>
            }
            <div className='flex flex-col gap-8'>
              <Typography type='Body' className={`${status === "Active" ? "" : "opacity-50"} select-text break-words whitespace-normal ${editable? "max-w-[50vw]" : "max-w-[70vw]"} lg:max-w-[35vw]`}>{name}</Typography>
              <Typography type='Body2' className={`text-main-gray select-text break-words whitespace-normal  ${editable? "max-w-[50vw]" : "max-w-[70vw]"} lg:max-w-[35vw]`}>{status === "Active" ? email : status === "Inactive" ? "Invitation Expired" : status}</Typography>
            </div>
          </label>
      
        {!editable &&         <div className='w-40 h-40 flex justify-center items-center cursor-pointer z-[70]'>
        <DropdownMenu
            dropdownEnabled={dropdownSettings === email}
            setDropdownEnabled={() => {
              if (isMobile) {
                setMoreSettings?.(email);
                setSelectedStudents([email])
              }else{
                setSelectedStudents([email])
                setDropdownSettings(dropdownSettings === email ? '' : email);
              }
            }}
            dropdownComponent={
              <div className="flex items-center">
      <div className="flex flex-1 flex-col  justify-center">
        <div className='flex gap-12 items-center' onClick={()=>{setDeleteStudentsModal(true)}}>
              <img src={TrashIcon} alt="" />
              <Typography type='Body2' className='text-[#DE1135]'>Remove user</Typography>
        </div>
        {status !== "Active" && <>
          <Divider marginY='my-8'/>
        <div className='flex gap-12 items-center' onClick={()=>{handleInviteClick();}}>
              <img src={ResendIcon} alt="" />
              <Typography type='Body2'>Resend invitation</Typography>
        </div>
        </>}
        
      </div>
    </div>
            }
            dropdownInnerContainerClassNames="mt-[-2px] !w-[200px]">
           
           <img src={MoreIcon} alt=""/>

          </DropdownMenu>         
        </div>}
    </div>
  )
}

export default StudentListItem
