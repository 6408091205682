import React from 'react'
import AuthContainer from '../components/AuthContainer/AuthContainer'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden'
import Header from '../components/Header/Header'
import TextWithBackButton from '../components/TextWithBackButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import InputField from '../components/InputField/InputField'
import Button from '../components/Button/Button'
import useRecovery from '../store/Mutations/usePasswordRecovery'
import { CreateNewPasswordSchema } from '../scemas/validations'
import { useToast } from '../context/ToastProvider'

const CreateNewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { addToast } = useToast();

    const { mutate: recoverPassword, isPending} = useRecovery({
      onSuccess: () => {
        addToast({
          headerText: "Reset successful!",
          message: "Password has been reseted successfully",
          type: "success",
        });
        navigate('/login');
      },
    });
  
    const token = new URLSearchParams(location.search).get('token');
  return (
    <AuthContainer>
       <div className="lg:rounded-20 bg-[#FBFBFE] flex min-h-svh w-full flex-col overflow-hidden bg-gray-50 lg:min-h-fit lg:bg-white px-12 lg:p-24">
        <GridLGHidden>
          <Header backgroundColor='[#FBFBFE]'/>
        </GridLGHidden>
        <TextWithBackButton text='Create new password' backBtnClick={()=>navigate("/login")}/>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={CreateNewPasswordSchema}
          onSubmit={(values) => {
            if (values.password === values.confirmPassword) {
              recoverPassword({
                token: token || '',
                password: values.password,
              });
            } else {
              alert('Passwords do not match!');
            }
          }}

        >
          {({values, setFieldValue, errors, handleBlur, touched}) => (
            <Form className='mt-24 lg:mt-72  w-full flex flex-col gap-20 lg:gap-24'>
                <InputField
                label={"New password"}
                required
                placeholder={"New password"}
                passwordWithIcon={true}
                passwordText={"8 characters"}
                type="password"
                id="createPassword-password"
                value={values.password}
                onChange={(e) => {
                    setFieldValue(
                      `password`,
                      e.target.value
                    );
                  }}
                onBlur={handleBlur("password")}   
                error={
                  touched.password &&
                  errors.password
                    ? errors.password
                    : ""
                } 
                />
                <InputField
                label={"Confirm new password"}
                required
                placeholder={"New password"}
                passwordWithIcon={true}
                passwordText={"Passwords must match"}
                type="password"
                id="createPassword-confirm"
                value={values.confirmPassword}
                onChange={(e) => {
                    setFieldValue(
                      `confirmPassword`,
                      e.target.value
                    );
                  }}
                onBlur={handleBlur("confirmPassword")}   
                error={
                  touched.confirmPassword &&
                  errors.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }   
              />
              <div className='lg:my-48 absolute lg:relative lg:bottom-0 bottom-24 left-0 w-full px-12 lg:px-0'>
              <Button
                buttonType="primary"
                type="submit"
                testID="reset-password"
                title={"Reset password"}
                loading={isPending}
              />
              </div>
            </Form>
          )}
        </Formik>    
       </div>  
    </AuthContainer>
  )
}

export default CreateNewPassword
