import React, { useEffect, useLayoutEffect, useState } from 'react'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden'
import Header from '../components/Header/Header'
import GridComponent from '../components/GridComponent/GridComponent'
import { LeftSideComponent } from '../components/Sidebar/Sidebar'
import GridTitle from '../components/GridComponent/components/GridTitle'
import { useNavigate } from 'react-router-dom'
import Typography from '../components/Typography/Typography';
import Divider from '../components/Divider/Divider';
import Button from '../components/Button/Button'
import useSingleOrganisation from '../store/Querys/useSingleOrganization'
import useOrganisation from '../store/Querys/useOrganisation'
import useCancelSubscription from '../store/Mutations/useCancelSubscription'
import PurchaseAdditionalLicensesModals from '../components/modals/PurchaseAdditionalLicensesModals'
import ModalComponent from '../components/modals/ModalComponent'
import { ISingleOrganization, User } from '../store/interface'
import { useToast } from '../context/ToastProvider'

const Billing = () => {
  const [purchaseLicensesModal, setPurchaseLicensesModal] = useState(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#F5F5FC');
  }, []);

  const {data: organizationsData, refetch} = useOrganisation();
  const organizationId = organizationsData?.organizations?.[0]?.id ;
  const {data: adminData, refetch: refetchAminData} = useSingleOrganisation(organizationId);

  const { addToast } = useToast(); 

  const [cancelled, setCancelled] =  useState(organizationsData?.organizations?.[0]?.b2bSubscriptions.find((sub) => sub.status === 'active')?.toBeCancelled || false);

  const { mutate: cancelSubscription } = useCancelSubscription({
    onSuccess: () => {
      addToast({
        headerText: "Subscription canceled successfully!",
        type: 'success',
        message: ""
      });
      refetch();
      setCancelled(true);
      setTimeout(()=>{
        setCancelSubscriptionModal(false);
      }, 1000)
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      addToast({
        message: errorMessage,
        type: 'error',
      });
    },
  });

  const handleCancelSubscription = () => {
    if (organizationId) {
      cancelSubscription({ organizationId});
    } else {
      console.log('Organization ID is missing.');
    }
  };

  return (
    <div className='flex flex-col lg:flex-row bg-[#F5F5FC] lg:bg-transparent'>
      {purchaseLicensesModal && <PurchaseAdditionalLicensesModals closeBtnClick={()=> {refetchAminData(); setPurchaseLicensesModal(false)}}/>}
      <GridLGHidden>
        <Header backgroundColor='[#F5F5FC]'/>
      </GridLGHidden>  
      <GridComponent
          leftSideComponent={
            <LeftSideComponent/>
          }
          containerClassNames={
            'lg:grid-cols-grid-chat-no-right-lg'
          }
          mainComponent={
          <MainComponent  
            setPurchaseLicensesModal={setPurchaseLicensesModal} 
            setCancelSubscriptionModal={setCancelSubscriptionModal}
            organizationsData = {organizationsData}
            adminData={adminData}
            setCancelled={setCancelled}
            cancelled={cancelled}
          />}
        //  mainComponentContainerClassNames="max-w-[1083px]"
        />
       <ModalComponent
        type={'warning'}
        setOpen={setCancelSubscriptionModal}
        open={cancelSubscriptionModal}
        title={"Are you sure you want to cancel the subscription?"}
        description={"Canceling will result in all users under this account losing access to Talkpal Premium features."}
        primaryButtonText={"Yes"}
        withCancel={true}
        cancelButtonText={"Close"}
        onCancel={() => {
          setCancelSubscriptionModal(false);
        }}
        onSubmit={handleCancelSubscription}
      />  
    </div>
  )
}

export default Billing;


  const MainComponent = ({
    setPurchaseLicensesModal,
    setCancelSubscriptionModal,
    organizationsData,
    adminData,
    setCancelled,
    cancelled
  }:{
    setPurchaseLicensesModal: (val: boolean)=> void; 
    setCancelSubscriptionModal: (val: boolean)=> void;
    organizationsData?: User;
    adminData?: ISingleOrganization;
    setCancelled: (val:boolean) => void;
    cancelled: boolean;
  
  }) => {

    const navigate = useNavigate();
    
  const [subscription, setSubscription] = useState(
    organizationsData?.organizations?.[0]?.b2bSubscriptions.find((sub) => sub.status === 'active')
  );

  useEffect(() => {
    if (organizationsData) {
      const activeSubscription = organizationsData.organizations[0].b2bSubscriptions.find(
        (sub) => sub.status === 'active'
      );
      setSubscription(activeSubscription);
      setCancelled(activeSubscription?.toBeCancelled || false);
    }
  }, [organizationsData]);


    let packageName = "";

    switch(subscription?.package){
      case "Biennial Subscription":
        packageName = "Premium 24 Months";
        break;
      case "Yearly Subscription":
        packageName = "Premium 12 Months";
         break;  
      case "Quarterly Subscription":
       packageName = "Premium 3 Months";
        break;     
    }
    
    const billingUrl = process.env.REACT_APP_ENV === 'prod'
  ? 'https://pay.talkpal.ai/p/login/00g2b8cLPg1Q0ve4gg' 
  : 'https://pay.talkpal.ai/p/login/test_6oE17C9sDbjd8AEbII'; 

    return <div className='flex flex-1 flex-col h-svh'>
        <GridTitle title='Billing' backBtnClick={()=> navigate("/account")}/>
        <GridLGHidden>
          <Typography type='Body2' className='text-main-gray ml-[37px] mt-10 mb-24'>Manage your subscriptions below.</Typography>
        </GridLGHidden>  
        <div className='flex gap-48 lg:gap-72 flex-col lg:flex-row'>
          <div className='rounded-20 bg-primary p-24 flex-1 relative'>
            <Typography type='Header3' typeSM='Body' className='text-white mb-24 lg:mb-40'>Your Plan</Typography>
            <Typography type='Header2' typeSM='Header3' className='text-white font-bold mb-12'>{packageName}</Typography>
            <Typography type='Body3' className='text-secondary mb-24 lg:mb-40'>{cancelled ? "Expires on" : "Renews on"} { subscription?.nextBillingDate && 
              new Date(subscription.nextBillingDate * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })}</Typography>
            <Button title='Billing management' buttonType='secondary' styles='bg-white !text-primary lg:mt-[77px]' onClick={() => window.open(billingUrl, '_blank')}/>
              {cancelled ? 
               <Typography type='Body3' className='!text-main-gray absolute -bottom-24 left-1/2 -translate-x-1/2'>Cancelled</Typography> :
               <Typography type='Body3' className='!text-[#DE1135] cursor-pointer underline underline-offset-4 absolute -bottom-24 left-1/2 -translate-x-1/2' onClick={()=>setCancelSubscriptionModal(true)}>Cancel subscription</Typography>
              }
          </div>
          <div className='rounded-20 bg-white p-24 flex-1 mb-24 lg:mb-0 border border-light-grey-10'>
            <Typography type='Header3' typeSM='Body'>License breakdown</Typography>
            <Divider/>
            <div className='flex justify-between items-center mb-8'>
              <Typography type='Body2'>Current amount of licenses</Typography>
              <Typography type='Body' className='text-primary'>{adminData?.totalLicenses}</Typography>
            </div>
            <div className='flex justify-between items-center mb-8'>
              <Typography type='Body2'>Used licenses</Typography>
              <Typography type='Body' className='text-primary'>{adminData?.usedLicenses}</Typography>
            </div>
            <div className='flex justify-between items-center mb-24'>
              <Typography type='Body2'>Remaining licenses</Typography>
              <Typography type='Body' className='text-primary'>{adminData?.totalLicenses && adminData?.totalLicenses - adminData?.usedLicenses}</Typography>
            </div>
            <Button title='Purchase additional licenses' buttonType='outline' className='outline-button' styles='text-primary' onClick={()=>setPurchaseLicensesModal(true)}/>
          </div>
        </div>
    </div>
}