import SuccessIcon from '../../assets/icons/bx-check-blue.svg';
import EnvelopeIcon from '../../assets/icons/bx-envelope-blue.svg';
import WarningIcon from '../../assets/icons/bx-error-circle-blue.svg';
import TrashIcon from '../../assets/icons/bx-trash-red.svg';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import CloseIcon from '../../assets/icons/bx-x-blue.svg';
import Divider from '../Divider/Divider';
import CopyIcon from '../../assets/icons/bx-copy-blue.svg';
import { useState } from 'react';

interface ModalComponentProps {
  open: boolean;
  type: 'success' | 'error' | 'warning' | 'delete' | 'email' | 'password';
  title: string;
  description: string;
  primaryButtonText: string;
  withCancel?: boolean;
  // WHEN MODAL IS CLOSING
  onClose?: () => void;
  // WHEN MODAL IS SUBBMITED , + MODAL CLOSING AFTER SUBMIT
  onSubmit?: () => void;
  // WHEN MODAL IS CANCELLED , + MODAL CLOSING AFTER CANCEL
  onCancel?: () => void;
  cancelButtonText?: string;
  className?: string;
  withCloseButton?: boolean;
  setOpen?: (val: boolean) => void;
  primaryBtnLoading?: boolean;
  primaryButtonDisabled?: boolean;
}

const ModalComponent = ({
  open = false,
  type = 'success',
  title = '',
  description = '',
  primaryButtonText = '',
  cancelButtonText = 'Cancel',
  withCancel,
  onClose,
  onSubmit,
  onCancel,
  withCloseButton,
  setOpen,
  className = '',
  primaryBtnLoading,
  primaryButtonDisabled
}: ModalComponentProps) => {
  const [resendButtonTimer, setResendButtonTimer] = useState(10);

  let timer: NodeJS.Timeout | undefined;

  const timerFunc = () => {
    if (timer) clearInterval(timer);

    timer = setInterval(() => {
      setResendButtonTimer(prev => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(timer);
          return 10;
        }
      });
    }, 1000);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{ zIndex: 1000 }}
          className={`bg-light-grey-7 fixed bottom-0 left-0 right-0 top-0 z-50 flex h-lvh w-full items-center justify-center px-44`}>
          <div
            className={`${className}  max-w-280 lg:max-w-300 mx-auto flex flex-col gap-32 justify-self-center rounded-2xl bg-white p-20 lg:mx-36 lg:gap-24`}>
            <div className="flex w-full items-center justify-between">
              {type === 'success' && <div className="bg-secondary flex h-40 w-40 items-center justify-center rounded-full"> <img src={SuccessIcon} alt="" /> </div>}
              {type === 'email' && (
                <div className="bg-secondary flex h-40 w-40 items-center justify-center rounded-full">
                  <img src={EnvelopeIcon} alt="" />
                </div>
              )}
              {type === 'delete' && (
                <div className="bg-[#F8CFD7] flex h-40 w-40 items-center justify-center rounded-full">
                  <img src={TrashIcon} alt="" />
                </div>
              )}
              {type === 'error' && (
                <div className="bg-secondary flex h-40 w-40 items-center justify-center rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none">
                  <path
                    d="M11.001 10.5H13.001V15.5H11.001V10.5ZM11 16.5H13V18.5H11V16.5Z"
                    fill="#2E3BC7"
                  />
                  <path
                    d="M13.7689 4.70067C13.4209 4.04567 12.7429 3.63867 12.0009 3.63867C11.2589 3.63867 10.5809 4.04567 10.2329 4.70167L2.89493 18.5647C2.73209 18.8691 2.65143 19.2107 2.6609 19.5558C2.67037 19.9009 2.76964 20.2376 2.94893 20.5327C3.12562 20.8291 3.37657 21.0742 3.67697 21.244C3.97738 21.4137 4.31687 21.5022 4.66193 21.5007H19.3399C20.0479 21.5007 20.6889 21.1387 21.0539 20.5327C21.2329 20.2375 21.332 19.9009 21.3415 19.5558C21.351 19.2108 21.2705 18.8692 21.1079 18.5647L13.7689 4.70067ZM4.66193 19.5007L12.0009 5.63767L19.3449 19.5007H4.66193Z"
                    fill="#2E3BC7"
                  />
                </svg>
                </div>
              )}
              {/* {error =} */}
              {(type === 'warning' || type === 'password') && (
                <div className="bg-secondary flex h-40 w-40 items-center justify-center rounded-full">
                  <img src={WarningIcon} alt="" />
                </div>
              )}
              {withCloseButton && (
                <img
                  src={CloseIcon}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => setOpen && setOpen(false)}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <Typography type="Header3">{title}</Typography>
              <Typography type="Body2" className="!text-[#BBBBBB]">
                {description}
              </Typography>
            </div>
            {(type === 'email' || title === "Payment failed" || title === "Inactive account") && (
              <div>
                <Divider marginY="my-0 mb-16" />
                <div className="flex items-center justify-between">
                  <Typography type="Body2" className="!text-[#BBBBBB]">
                    business@talkpal.ai
                  </Typography>
                  <img
                    src={CopyIcon}
                    alt=""
                    className="cursor-pointer"
                    onClick={() =>
                      navigator.clipboard.writeText('support@talkpal.ai')
                    }
                  />
                </div>
                <Divider marginY="my-0 mt-16" />
              </div>
            )}
            <div className="flex flex-col gap-12">
              <Button
                buttonType={
                  type === 'delete'
                    ? 'outline'
                    : (type === 'email' && resendButtonTimer < 10) ||
                      type === 'password' || title === "Are you sure you want to cancel the subscription?"
                    ? 'secondary'
                    : 'primary'
                }
                testID="button"
                styles={`${
                  type === 'email' && resendButtonTimer < 10
                    ? 'bg-[#F3F3F3] hover:bg-[#F3F3F3] cursor-text'
                    : ''
                }`}
                title={
                  type === 'email' && resendButtonTimer < 10
                    ? primaryButtonText + ' ... ' + resendButtonTimer
                    : primaryButtonText
                }
                textStyles={
                  type === 'delete'
                    ? 'text-talklap-red'
                    : type === 'email' && resendButtonTimer < 10
                    ? '!text-[#BBBBBB]'
                    : ''
                }
                onClick={() => {
                  if (type === 'email' && resendButtonTimer === 10) {
                    onSubmit?.();
                    // onClose?.();
                    timerFunc();
                  } else if (type === 'email' && resendButtonTimer < 10) {
                    console.log('');
                  } else {
                    onSubmit?.();
                    onClose?.();
                  }
                }}
                loading={primaryBtnLoading}
                type='button'
                disabled={primaryButtonDisabled}
              />
              {withCancel && (
                <Button
                  buttonType={
                    type === 'delete' || type === 'password' || title === "Are you sure you want to cancel the subscription?"
                      ? 'primary'
                      : type === 'email'
                      ? 'secondary'
                      : 'outline'
                  }
                  testID="cancel"
                  title={cancelButtonText}
                  onClick={() => {
                    onCancel?.();
                    onClose?.();
                  }}
                  type='button'
                />
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalComponent;
