import React, { useState } from 'react'
import AuthContainer from '../components/AuthContainer/AuthContainer'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden';
import Header from '../components/Header/Header';
import TextWithBackButton from '../components/TextWithBackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import RobotImage from "../assets/images/img-robot.svg";
import Divider from '../components/Divider/Divider';
import Button from '../components/Button/Button';
import ModalComponent from '../components/modals/ModalComponent';
import useRecoveryRequest from '../store/Mutations/useRecoveryRequest ';
import { useToast } from '../context/ToastProvider';

const CheckEmail = () => {
    const navigate = useNavigate();
    const [notReciveModal, setNotReciveModal] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const { addToast } = useToast(); 

    const {mutate: mutate} = useRecoveryRequest();

    const handleResendEmail = () => {
      if (email) {
        mutate(
          { email, source: "web" },
          {
            
            onSuccess: () => {
              addToast({
                headerText: "Email resent",
                message: "Email has been resent successfully",
                type: "success",
              });
            },
          }
        );
      }
    };

  return (
    <AuthContainer>
      <div className="lg:rounded-20 bg-[#FBFBFE] lg:bg-white w-full flex min-h-svh flex-col lg:min-h-fit px-12 lg:p-24">
      <GridLGHidden>
        <Header  backgroundColor='[#FBFBFE]'/>
      </GridLGHidden>
      <TextWithBackButton text='Check your email' backBtnClick={()=> navigate("/reset-password")} subText='We just sent you a password reset link to your email address.' subText2='If you did not receive it, please check your spam folder or click below.'/>
      <div className="absolute bottom-24 left-0 flex w-full px-12 lg:px-0 flex-col items-center  lg:relative lg:bottom-0 lg:mt-24">
        <img
          src={RobotImage}
          alt=""
          width={300}
          className=""
        />

        <Divider margin="24" className="mb-0 mt-0 w-full" />
        <Button
          buttonType="secondary"
          title={"I did not receive the email"}
          testID="email-not-recieved"
          styles="mt-12"
          onClick={()=>setNotReciveModal(true)}
        />
      </div>
    </div>

    <ModalComponent
        type={'email'}
        withCloseButton={true}
        setOpen={setNotReciveModal}
        open={notReciveModal}
        title={"Did not receive the email?"}
        description={"Please check your spam folder to make sure you didn’t miss it. You can also resend the email or contact support."}
        primaryButtonText={"Resend email"}
        withCancel={true}
        cancelButtonText={"Close"}
        onCancel={() => {
          setNotReciveModal(false);
        }}
        onSubmit={handleResendEmail}
        onClose={() => {
          setNotReciveModal(false);
        }}
      />
    </AuthContainer>
  )
}

export default CheckEmail
