import React, { useState } from 'react'
import Typography from '../Typography/Typography'
import InputField from '../InputField/InputField'
import Button from '../Button/Button'
import CloseIcon from "../../assets/icons/bx-x.svg";
import BackBtn from "../../assets/icons/bx-up-arrow-alt.svg";
import Divider from '../Divider/Divider';
import useOrganisation from '../../store/Querys/useOrganisation';
import useSingleOrganization from '../../store/Querys/useSingleOrganization';
import useUpdateSubscription from '../../store/Mutations/useUpdateSubscription';
import { useToast } from '../../context/ToastProvider';

const prices = [
  {
    plan: "Biennial Subscription",
    price: 119.99,
  },
  {
    plan: "Yearly Subscription",
    price: 71.99,
  },
  {
    plan: "Quarterly Subscription",
    price: 29.99
  }
]

const PurchaseAdditionalLicensesModals = ({closeBtnClick, handleUpdateLicenses}:{closeBtnClick: ()=> void; handleUpdateLicenses?: ()=> void;}) => {
    const [step, setStep] = useState(1);
    const [additionalLiscenses, setAdditionalLiscenses] = useState(10);
    const [inputValue, setInputValue] = useState("10");
    const { addToast } = useToast(); 
    const [error, setError] = useState(false);

    const {data: organizationsData} = useOrganisation();
    const organizationId = organizationsData?.organizations?.[0]?.id ;
    const {data} = useSingleOrganization(organizationId);

    const [subscription, setSubscription] = useState(
      organizationsData?.organizations?.[0]?.b2bSubscriptions.find((sub) => sub.status === 'active')
    );
    const plan = subscription?.package;

    const {mutate: updateSubscriptionMutation, isPending } = useUpdateSubscription();

    const handlePurchaseClick = () => {
      if (organizationId) {
        updateSubscriptionMutation(
          {
            organizationId,
            licenseCount: additionalLiscenses,
            prorationCheck: false, 
          },
          {
            onSuccess: () => {
              handleUpdateLicenses?.();
              addToast({
                headerText: subscription?.toBeCancelled ? 'Purchase not possible' : "Licenses purchased",
                type: subscription?.toBeCancelled ? "warning" : 'success',
                message: subscription?.toBeCancelled ? "Purchasing additional licenses is not possible with cancelled subscription." : "Additional licenses have been purchased successfully."
              });
              setTimeout(closeBtnClick, 2000);
            },
            onError: (error) => {
              console.error("Error updating subscription:", error);
              setTimeout(closeBtnClick, 2000);
            },
          }
        );
      }
    };

    const handleContinue = () => {
      if (inputValue === "" || additionalLiscenses === 0 || (((data?.totalLicenses ? data?.totalLicenses : 0) + additionalLiscenses) >= 1000)) {
        setError(true);
      } else {
        setError(false);
        setStep(2);
      }
    };

  return (
    <div className='w-full h-lvh bg-[#9294a4cc] flex items-center justify-center fixed top-0 left-0 z-[999999] px-12'>
      {step === 1 && <div className='bg-white rounded-20 p-24 max-w-[573px] w-full'>
        <div className='flex justify-between items-center mb-48'>
            <Typography type='Header3'>Purchase additional licenses</Typography>
            <img src={CloseIcon} alt='' className='cursor-pointer' onClick={closeBtnClick}/>
        </div>
        <InputField
            customInputStyles='!border-light-grey-10 !border-[2px]'
            type='number'
            label="How many licenses would you like to purchase?"
            required
            placeholder="10"
            min={1}
            max={999}
            value={inputValue}
            onKeyDown={(e) => {
              if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let value = e.target.value.trim(); 
              value = value.replace(/[^0-9]/g, '');
              if (value !== "") {
                value = String(parseInt(value, 10)); 
              }             
              setInputValue(value);
              setAdditionalLiscenses(Number(value)); 
              const numericValue = Number(value);
              if ( value === "0" || numericValue > (999 - (data?.totalLicenses ? data?.totalLicenses : 0))) {
                setError(true);
              } else {
                setError(false);
                setAdditionalLiscenses(numericValue); 
              }
            }}
        />
        {error && (
              <Typography type="Body3" className="text-talklap-red mt-2 ml-4">
                {additionalLiscenses > 999 - (data?.totalLicenses || 0)
                  ? `License count cannot be more than ${999 - (data?.totalLicenses || 0)}.`
                  : additionalLiscenses === 0
                  ? "License count cannot be zero."
                  : "Only numeric values are allowed."}             
              </Typography>
            )}
        <Button title='Continue' buttonType='primary' styles='mt-24' onClick={handleContinue}/>
      </div>}
      {step === 2 && <div className='bg-white rounded-20 p-24 max-w-[442px] w-full'>
        <div className='flex items-center mb-24 gap-12'>
            <img src={BackBtn} alt='' className='cursor-pointer -rotate-90' onClick={()=>setStep(1)}/>
            <Typography type='Header3'>Purchase additional licenses</Typography>
        </div>
        <div className='flex justify-between h-48 items-center mb-12'>
            <Typography type='Body2'>Current licenses</Typography>
            <Typography type='Body2'>{data?.totalLicenses}</Typography>
        </div>
        <div className='flex justify-between h-48 items-center mb-12'>
            <Typography type='Body2'>Additional licenses</Typography>
            <Typography type='Body2'>{additionalLiscenses}</Typography>
        </div>
        <div className='flex justify-between h-48 items-center'>
            <Typography type='Body2'>Total new amount</Typography>
            <Typography type='Body2'>{data?.totalLicenses && data?.totalLicenses + additionalLiscenses}</Typography>
        </div>
        <Divider marginY='my-12'/>
        <div className='flex justify-between h-48 items-center mb-12'>
            <Typography type='Body2'>You will be charged:</Typography>
            <Typography type='Body2' className='font-bold'>${Math.round(Number(prices.find((item) => item.plan === plan)?.price) * additionalLiscenses * 100)/100}</Typography>
        </div>

        <Button title='Purchase' buttonType='primary' loading={isPending} onClick={()=> {handlePurchaseClick(); }}/>
      </div>}
    </div>
  )
}

export default PurchaseAdditionalLicensesModals
